const { api } = useApi()

const loading = ref({
  banks: false,
  savedBanks: false,
  bankError: false,
  savedBankError: false,
  paymentOption: false,
  paymentOptionError: false,
})
const errorMessage = ref('')

export default function usePayment() {
  // Reusable composables
  const { banks, savedBanks } = storeToRefs(usePaymentStore())
  const { logError } = useSentry()
  const { paymentOptions } = storeToRefs(usePaymentStore())

  async function fetchBanks() {
    try {
      loading.value.banks = true
      loading.value.bankError = false
      const response = await api.payment.fetchBanks()
      if (response) {
        banks.value = response.data
      }
      loading.value.banks = false
    } catch (err: any) {
      loading.value.banks = false
      loading.value.bankError = true
      errorMessage.value = formatErrorResponse(err)
      logError(err)
    }
  }
  async function fetchSavedBanks() {
    try {
      loading.value.savedBanks = true
      loading.value.savedBankError = false
      const response = await api.payment.fetchUserBanks()
      if (response.success) {
        savedBanks.value = response.data
      }
      loading.value.savedBanks = false
    } catch (err) {
      loading.value.savedBanks = false
      loading.value.savedBankError = true
      errorMessage.value = formatErrorResponse(err)
      logError(err)
    }
  }
  async function fetchPaymentOptions() {
    try {
      loading.value.paymentOption = true
      loading.value.paymentOptionError = false
      const response = await api.misc.fetchConfigurations()
      loading.value.paymentOption = false
      if (response) {
        paymentOptions.value = response.data.paymentOptions
      }
    } catch (err) {
      loading.value.paymentOption = false
      loading.value.paymentOptionError = true
      logError(err)
    }
  }

  return {
    loading,
    errorMessage,
    fetchBanks,
    fetchSavedBanks,
    fetchPaymentOptions,
  }
}
